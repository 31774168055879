import './sidebar';
import './close_button';
import './dropdown';
import './thumbnails';
import './profiles';
import './image-preview';
import './submit-page';
import './clear-other-inputs';
import './disable-prompt';
import './is-pwa-installed';
import './dropdown-tools';
import './search';
import './pwa-button-visibility';
import './keyboardAwareForm';
import './scroll-to-bottom';
import './clear-message-input';
import './list-selection';
import './scroll-to-item';
import './auto_resize_textarea';
import './notifications-dropdown';
import './conditional-nav-display';
import './theme-switch';
import './profile_tabs_handler';
import './conditional-bottom-nav-display';
import './rooms-list-page-scroll';
import './back-button-updates';
import './ui-updates';
import './navigation';
import './related_posts_visibility';
import './clickableLinkElement';
import './scroll-manager'

// Empty footer
$(document).ready(function() {
  let isStandalone = false;

  if (window.matchMedia('(display-mode: standalone)').matches) {
    isStandalone = true;
  }

  if (!isStandalone) {
    $(".empty-footer").css("min-height", "2.5rem");
  }
});

document.addEventListener('turbolinks:load', function() {
  if (window.matchMedia('(max-width: 1200px)').matches) {
    const tabs = document.querySelectorAll('.tab-link');

    tabs.forEach(tab => {
      tab.addEventListener('click', function(event) {
        event.preventDefault();
        const url = this.getAttribute('href');
        Turbolinks.visit(url, { action: 'replace' });
      });
    });
  }
});

