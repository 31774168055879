import SlimSelect from 'slim-select';
import 'slim-select/dist/slimselect.css';

const SUBFORUM_REGEX = /a\/([a-zA-Z-]+)\/?/;

const getTurbolinks = () => {
  if (typeof Turbolinks !== 'undefined') {
      return Turbolinks;
  } else if (typeof require !== 'undefined') {
      try {
          return require('turbolinks');
      } catch (e) {
          console.error('Failed to load Turbolinks:', e);
      }
  }
  return null;
};

const turbolinks = getTurbolinks();

document.addEventListener('turbolinks:load', () => {
  initializeSlimSelect();
  setupResponsiveHandling();
  restoreScrollPosition();
});

document.addEventListener('turbolinks:before-visit', () => {
  saveScrollPosition();
});

document.addEventListener('turbolinks:before-cache', () => {
  if (window.slimSelectInstance) {
    window.slimSelectInstance.destroy();
  }
});

function setupResponsiveHandling() {
  let lastWidth = window.innerWidth;

  window.addEventListener('resize', () => {
    const currentWidth = window.innerWidth;
    const crossedBreakpoint = (lastWidth <= 900 && currentWidth > 900) || (lastWidth > 900 && currentWidth <= 900);

    if (crossedBreakpoint) {
      initializeSlimSelect();
    }

    lastWidth = currentWidth;
  });
}

function initializeSlimSelect() {
  const isMobile = window.innerWidth <= 900;
  const selector = isMobile ? '.country-selector-container-mobile select' : '.country-selector-container-desktop select';
  const selectElement = document.querySelector(selector);

  if (!selectElement) {
    console.error('Select element not found for selector:', selector);
    return;
  }

  if (window.slimSelectInstance) {
    window.slimSelectInstance.destroy();
  }

  const container = document.querySelector(isMobile ? '.country-selector-container-mobile' : '.country-selector-container-desktop');
  if (container) {
    container.style.visibility = 'hidden';
  }

  const matches = window.location.pathname.match(SUBFORUM_REGEX);
  const subforumSlug = matches ? normalizeString(matches[1]) : null;

  window.slimSelectInstance = new SlimSelect({
    select: selectElement,
    data: [{
      text: 'Forum',
      value: '',
      placeholder: true,
      class: 'chicken',
    }, ...gon.subforum_options.map(option => {
      const normalizedText = normalizeString(option.text);
      const imageUrl = `/assets/images/flags/${normalizedText.replace(/\s+/g, '-')}.svg`;
      return {
        text: option.text,
        value: option.id.toString(),
        selected: normalizeString(option.url).endsWith(subforumSlug),
        html: `<img src="${imageUrl}" style="width: 20px; height: 20px; margin-right: 10px;"> <span class="option-text">${option.text}</span>`,
        data: { url: option.url }
      };
    })],
    placeholder: 'Forum',
    allowDeselect: true,
    deselectLabel: '<span>&times;</span>',
    events: {
      afterOpen: () => {
        applyCustomStyles();
      },
      afterChange: () => {
        applyCustomStyles();
        updateSelectedDisplayForMobile(isMobile, selectElement);
        navigateToSelectedOption(selectElement);
      }
    },
    onChange: () => {
      updateSelectedDisplayForMobile(isMobile, selectElement);
    }
  });

  selectElement.addEventListener('change', () => {
    saveScrollPosition();
    navigateToSelectedOption(selectElement);
  });

  if (isMobile) {
    updateSelectedDisplayForMobile(isMobile, selectElement);
  }

  setTimeout(() => {
    applyCustomStyles();
    if (container) {
      container.style.visibility = 'visible';
    }
  }, 0);

  const observer = new MutationObserver((mutationsList) => {
    for (const mutation of mutationsList) {
      if (mutation.type === 'childList' || mutation.type === 'attributes') {
        updateSelectedDisplayForMobile(isMobile, selectElement);
      }
    }
  });

  observer.observe(document.querySelector('.ss-main'), {
    childList: true,
    attributes: true
  });
}

function applyCustomStyles() {
  const ssContent = document.querySelector('.ss-content');
  if (ssContent) {
    ssContent.classList.add('custom-ss-content');
  }
}

function navigateToSelectedOption(selectElement) {
  const selectedValue = selectElement.value;
  const selectedOption = gon.subforum_options.find(option => option.id.toString() === selectedValue);

  if (selectedOption && selectedOption.url) {
      saveScrollPosition();
      if (turbolinks) {
          turbolinks.visit(selectedOption.url);
      } else {
          window.location.href = selectedOption.url;
      }
  } else {
      console.error('Failed to navigate: URL is undefined or option data is incorrect', selectedOption);
  }
}

function updateSelectedDisplayForMobile(isMobile, selectElement) {
  if (!isMobile) return;

  let selectedValue = selectElement.value;
  const selectedData = gon.subforum_options.find(option => option.id.toString() === selectedValue);

  if (selectedData) {
    const imageUrl = `/assets/images/flags/${normalizeString(selectedData.text).replace(/\s+/g, '-')}.svg`;
    const selectedTextContainer = document.querySelector('.ss-main .ss-values .ss-single');
    if (selectedTextContainer) {
      selectedTextContainer.innerHTML = `<img src="${imageUrl}" style="width: 20px; height: 20px;">`;
    }
  }
}

function normalizeString(str) {
  return str.toLowerCase().replace(/\s+/g, '-').replace(/-+/g, '-');
}

function saveScrollPosition() {
  sessionStorage.setItem('scrollPosition', window.pageYOffset.toString());
}

function restoreScrollPosition() {
  const savedPosition = sessionStorage.getItem('scrollPosition');
  if (savedPosition !== null) {
      window.scrollTo(0, parseInt(savedPosition, 10));
      sessionStorage.removeItem('scrollPosition');
  }
}